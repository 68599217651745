
import { useRouter } from "vue-router";
import {
  defineComponent,
  inject,
  ref,
  reactive,
  Ref,
  onMounted,
  computed,
} from "vue";
import {
  PhoneOutlined,
  MessageOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons-vue";
import Doctor from "../components/Doctor.vue";
import { useStore } from "../store";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import { map, mapValues, assign } from "lodash";
import { TableState } from "ant-design-vue/lib/table/interface";
import { AxiosStatic } from "axios";
import {
  AppointmentType,
  AdditionalSlot,
  AdditionalSlots,
  CommunicationTypes,
  OrderExt,
  PatientType,
  IdType,
} from "../../types";
import { actions } from "../utils/const";
import { usePermissions } from "../composable/usePermissions";

interface AdditionalSlotData {
  data: Omit<AdditionalSlots, "page" | "size" | "totalElements"> & {
    additionalSlots: Array<
      AdditionalSlot & { communicationTypes: Array<CommunicationTypes> }
    >;
  };
  loading: boolean;
  pagination: TableState["pagination"];
  selected: Array<string>;
  columns: TableState["columns"];
}

interface OrderModel extends OrderExt {
  loading?: boolean;
}

export const OrderShortComponent = defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    Doctor,
    PhoneOutlined,
    MessageOutlined,
    VideoCameraOutlined,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const { hasPermission } = usePermissions();

    const appType = store.state.runtimeConfig.type;

    const additionalSlot: AdditionalSlotData = reactive({
      data: {
        additionalSlots: [],
        doctors: {},
        specializations: {},
        communicationTypes: ["CALL"],
      },
      loading: true,
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
      },
      selected: [],
      columns: [
        {
          title: "",
          dataIndex: "doctor",
          width: 500,
          slots: { customRender: "doctor" },
        },
        {
          title: "",
          width: 250,
          dataIndex: "communicationTypes",
          slots: { customRender: "communicationTypes" },
        },
        {
          title: "",
          dataIndex: "price",
          slots: { customRender: "price" },
        },
      ],
    });
    const loading: Ref<boolean> = ref(false);
    const data: Ref<OrderModel> = ref({
      id: 0,
      doctor: {
        id: 0,
        firstName: "",
        lastName: "",
        specializations: [{ id: 0, name: "" }],
      },
      loading: false,
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      price: 0,
      specializationId: 0,
      country: { id: 0, name: "" },
      adultsCount: 0,
      childrenCount: 0,
      patients: [],
      address: {
        city: "",
        postCode: "",
        streetName: "",
        streetNumber: "",
        country: "",
        flatNumber: "",
        additionalInfo: "",
      },
      email: "",
      phoneNumber: {
        number: "",
        prefix: "",
        pattern: "",
      },
      appointmentType: "CALL" as AppointmentType,
      setDefaultAddress: false,
      forNfz: false,
    });

    const refreshSlot = (id: number) => {
      return http.post("patient-portal/api/slot/extend", {
        id,
      });
    };

    const getAdditionalSlot = async (
      pagination: TableState["pagination"] = additionalSlot.pagination
    ) => {
      additionalSlot.loading = true;
      const link = `patient-portal/api/slot/additional?id=${props.id}&page=${
        (pagination?.current ?? 0) - 1
      }&size=${pagination?.pageSize}`;

      const additionalSlotRes = await http
        .get(link)
        .then((result) => result.data);
      additionalSlot.loading = false;
      additionalSlot.data.additionalSlots = additionalSlotRes.additionalSlots;
      additionalSlot.data.specializations = additionalSlotRes.specializations;
      additionalSlot.data.doctors = mapValues(
        additionalSlotRes.doctors,
        (doctor) => {
          doctor.avatarUrl = doctor.avatarUrl
            ? http.defaults.baseURL + "/files/" + doctor.avatarUrl
            : doctor.avatarUrl;
          return doctor;
        }
      );
      additionalSlot.data.additionalSlots = map(
        additionalSlot.data.additionalSlots,
        (slot) => {
          slot.communicationTypes = ["CALL"];
          return slot;
        }
      );
      additionalSlot.data.doctors = mapValues(
        additionalSlot.data.doctors,
        (doc) => {
          doc.specializations = map(doc.specializations, (spec) => {
            return {
              id: spec.id,
              name: additionalSlot.data.specializations[spec.id].name,
            };
          });

          return doc;
        }
      );

      additionalSlot.pagination = {
        current: additionalSlotRes.page,
        total: additionalSlotRes.totalElements,
        pageSize: additionalSlotRes.size,
      };
    };

    const reserveSlot = (slotId: AdditionalSlot["slot"]) => {
      return http
        .post("patient-portal/api/slot/reserve", {
          slotId,
          slotReservationId: props.id,
        })
        .then((result) => result.data);
    };

    const onRowAdditionalSlotTableSelection = (
      selectedRowKeys: Array<AdditionalSlot["slot"]>
    ) => {
      if (selectedRowKeys.length > 0) {
        additionalSlot.selected = [selectedRowKeys[selectedRowKeys.length - 1]];
      } else {
        additionalSlot.selected = [];
      }
    };

    const submit = async () => {
      loading.value = true;
      const order = { ...store.state.order };
      const processToTheNextPage = async (order: OrderModel) => {
        store.commit("setOrder", order);
        router.push({
          name: "OrderPersonal",
          params: { id: order.id },
        });
      };

      if (additionalSlot.selected.length > 0) {
        try {
          const newOrderId = await reserveSlot(additionalSlot.selected[0]);
          order.id = newOrderId;
          await router.replace({
            name: "Order",
            params: { id: newOrderId },
          });
          processToTheNextPage(order);
        } catch {
          loading.value = false;
          notification.open({
            message: t("ERROR.4889"),
            class: "error",
          });
        }
      } else {
        processToTheNextPage(order);
      }
    };

    const handleAdditionalSlotTableChange = (
      pagination: TableState["pagination"]
    ) => {
      getAdditionalSlot(pagination);
    };

    onMounted(async () => {
      data.value.loading = true;
      try {
        data.value = await http
          .get(`patient-portal/api/slot?id=${props.id}`)
          .then((res) => res.data);

        data.value.loading = false;
        data.value.id = Number(props.id);

        data.value.patients = [];
        data.value.doctor = {
          ...data.value.doctor,
          avatarUrl: data.value.doctor.avatarUrl
            ? http.defaults.baseURL + "/files/" + data.value.doctor.avatarUrl
            : data.value.doctor.avatarUrl,
        };
        const documentResponse = (
          await http.get("patient-portal/api/patient-profile")
        ).data;
        
        for (let i = 1; i <= data.value.adultsCount; i++) {
          data.value.patients.push({
            type: "ADULT" as PatientType,
            age: "",
            firstName: "",
            lastName: "",
            identificationDocument: {
              number: documentResponse.document.number,
              type: documentResponse.document.type as IdType,
            },
            birthdate: documentResponse.dateOfBirth,
            predefinedSymptoms: [],
            otherSymptoms: "",
          });
        }

        for (let j = 1; j <= data.value.childrenCount; j++) {
          data.value.patients.push({
            type: "CHILD" as PatientType,
            age: "",
            firstName: "",
            lastName: "",
            identificationDocument: {
              number: documentResponse.number,
              type: documentResponse.type as IdType,
            },
            birthdate: "2025-03-01T15:15:43.028Z",
            predefinedSymptoms: [],
            otherSymptoms: "",
          });
        }
        const order = assign(store.state.order, { ...data.value });
        store.commit("setOrder", order);

        try {
          await refreshSlot(order.id);
        } catch {
          store.commit("clearOrder");
          notification.open({
            message: t("ERROR.4865"),
            class: "error",
          });
          router.push({ name: "NfzVisitType" });
        }
        if (
          data.value.appointmentType === "CALL" &&
          hasPermission(actions.SHOW_OTHER_SLOTS)
        ) {
          try {
            await getAdditionalSlot(additionalSlot.pagination);
          } catch {
            notification.open({
              message: t("ERROR.4457"),
              class: "error",
            });
          }
        }
      } catch {
        notification.open({
          message: t("ERROR.4896"),
          class: "error",
        });
        router.push({ name: "NfzVisitType" });
      } finally {
        loading.value = false;
      }
    });

    return {
      t,
      loading,
      additionalSlot,
      data,
      submit,
      onRowAdditionalSlotTableSelection,
      handleAdditionalSlotTableChange,
      actions,
      hasPermission,
      translationType: computed(() =>
        [""].includes(appType) ? appType : "GENERAL"
      ),
    };
  },
});
export default OrderShortComponent;
